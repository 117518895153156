import React from 'react';
import './App.css';
import logo from './assets/jfct-logo.png'
import logoFooter from './assets/jfct-logo-white.png'

function App() {
  return (
    <div>

      <div className="hero-light">



        <div className='row'>
          <div className='logo'>
            <img src={logo} alt="JFCT Logo" />
          </div>
          <h1 className='hero-title'>
            JFCT
          </h1>
        </div>

        <div className='divider' />

        <div className='row-col'>

          <div className='box'>
            <div className='hero-subtitle'>About Us</div>
            <h1 className='hero-text'>
              <strong>JFCT</strong> is an innovative market participant that applies quantitative trading strategies to carefully targeted industries where such an approach is generally overlooked.
            </h1>
            {/* <br /> */}
            <div className='hero-text'>
              With a current focus in the live entertainment sector, our mission is to unlock opportunities in inefficient markets by providing optimal liquidity through the appliciation of rigorous technological, mathematical, and statistical methods.
            </div>
            <br />
            <div className='hero-text'>

              Our approach is fueled by creativity and a commitment to building a strong and scalable foundation that leverages data and technology to promote cutting-edge research as we expand our market reach.

            </div>
          </div>

          <div className='box'>
            <div className='hero-subtitle'>Get In Touch</div>
            <div className='row'>
              <div className='hero-text'>
                If our work sounds interesting and challenging to you and you believe you have the right skillset to contribute to our mission, we encourage you to send us your resume below.

              </div>
            </div>


            <br />
            <div className='row'>
              <div className='hero-text'>
                If you are an industry expert and believe there are market inefficiencies in your space where quantitative methods could be applied, we would love to get in touch.

              </div>
            </div>
            <br />

            <div className='row-col'>
              <a className='button mail-to' href="mailto:apply@jfct.dev?subject=JFCT%20Application">Apply</a>
              <br />
              <div className='gap' />
              <a className='button mail-to' href="mailto:contact@jfct.dev?subject=JFCT%20Industry%20Opportunity">Contact Us</a>

            </div>

          </div>
        </div>








      </div>
      <div className="footer">
        <div className='row-col'>

          <div className='footer-item'>

            <div className='col'>
              <h1 className='footer-title'>
                JFCT
              </h1>
            </div>
          </div>
          <div className='footer-item'>
            <div className='col'>
              <div className='footer-text-bold'>
                New York
              </div>
              <div className='footer-text'>
                222 Broadway Fl 22 Ste 1876
              </div>
              <div className='footer-text'>
                New York, NY 10038
              </div>
              <div className='footer-text'>
                (626) 720-3469
              </div>
            </div>
          </div>
          <div className='footer-item'>
            <div className='col'>

              <div className='logo-footer'>
                <img src={logoFooter} alt="JFCT Logo" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default App;
